import config_local from "./config.local.json";
import config_stg from "./config.stg.json";
import config_prod from "./config.prod.json";
const getConfig = () => {
    const environment = process.env.REACT_APP_ENV;
    console.log(`Environment ${environment}`);

    switch (environment) {
        case 'stg':
            return config_stg;
        case 'prod':
            return config_prod;

        default:
            return config_local;
    }
}

export default getConfig();

