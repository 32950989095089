import { requestHandler } from './axiosClient';
import { UMS } from "../config/apiPaths";
export const getUserDetails = async () => {

    try {
        // console.log('actions.userDetails');
        const options = {
            url: UMS.UserDetails,
            method: 'GET'
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }
};

export const addUser = async (body) => {

    try {
        // console.log('actions.addUser');
        const options = {
            url: UMS.AddUsers,
            method: 'POST',
            data: body
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }
};

export const logout = async () => {

    try {
        // console.log('actions.logout');
        const options = {
            url: UMS.Logout,
            method: 'POST'
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }
};

export const switchCustomer = async (customerId) => {
    try {
        const options = {
            url: `${UMS.SwithCustomer}/${customerId}`,
            method: 'PUT'
        }
        const data = await requestHandler(options);
        
        return { data }
    }
    catch (error) {
        return { error }
    }
}