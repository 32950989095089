export const styles = {
    root: {
        color: "#000000",
        display: "flex",
        justifyContent: "center",
        padding: "7px 0px 0px 0px",
        position: "absolute",
        bottom: 0,
        width: "100%",
    },
    content: {
        color: "#005F9E"
    }
}