import { requestHandler } from './axiosClient';
import { ProcessingFramework } from "../config/apiPaths";
import dataoceanConfig from "../config/dataoceanConfig";
export const startExecution = async (procedure_id, parameters) => {
    try {
        // console.log('actions.startExecution');
        const options = {
            url: ProcessingFramework.Executions,
            method: 'POST',
            data: {
                execution: {
                    procedure_id,
                    parameters
                }
            }
        }

        const response = await requestHandler(options);
        return { data: response.execution }
    }
    catch (error) {
        return { error }
    }
};

export const fetchAllExecutions = async (per_page = dataoceanConfig.paginationLimit) => {
    try {
        // console.log('actions.fetchAllExecutions');
        const options = {
            url: ProcessingFramework.Executions,
            method: 'GET',
            params: {
                per_page
            }
        }

        const response = await requestHandler(options);
        return { data: response.items }
    }
    catch (error) {
        return { error }
    }
};

export const fetchExecutionDetails = async (executionId) => {
    try {
        // console.log('actions.fetchExecutionDetails');
        const options = {
            url: `${ProcessingFramework.Executions}/${executionId}`,
            method: 'GET'
        }

        const response = await requestHandler(options);
        return { data: response.execution }
    }
    catch (error) {
        return { error }
    }
};
export const fetchExecutionActivities = async (executionId) => {
    try {
        // console.log('actions.fetchExecutionActivities');
        const options = {
            url: `${ProcessingFramework.ExecutionActivities.replace(":executionId", executionId)}`,
            method: 'GET',
            params: {
                per_page: 100
            }
        }

        const response = await requestHandler(options);
        return { data: response.items }
    }
    catch (error) {
        return { error }
    }
};
export const fetchExecutionEvents = async (executionId) => {
    try {
        // console.log('actions.fetchExecutionEvents');
        const options = {
            url: `${ProcessingFramework.ExecutionEvents.replace(":executionId", executionId)}`,
            method: 'GET',
            params: {
                per_page: 100
            }
        }

        const response = await requestHandler(options);
        return { data: response.items }
    }
    catch (error) {
        return { error }
    }
};