import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import ProgressSpinner from "../controls/ProgressSpinner";
import { startExecution } from "../../actions/executions_action";
import DisposableAlert from "../controls/DisposableAlert";
import { styles } from "../../style/procedures/ExecutionParameters.styles";
import insightsLogger from '../../insightsLogger';
const ExecutionParameters = ({ procedure }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [parameters, setParameters] = useState();
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(null);
    const executeProcedure = async () => {
        // console.log(parameters);
        // console.log(procedure);
        setIsLoading(true);
        const { data, error } = await startExecution(procedure.id, parameters);

        if (error) {
            setMessage(error);
        }
        else {
            insightsLogger.log('EXECUTIONS', 'START', parameters);

            setMessage(`Execution started with ID : ${data.id}`);
        }
        setIsError(error ? true : false);

        setIsLoading(false);
    };

    const setDefaultParams = () => {
        var params = { ...procedure.parameters };

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const paramValue = params[key];
                params[key] = paramValue.default_value || "";
            }
        }
        setParameters(params);
    }
    const onChange = (key, value) => {
        var params = { ...parameters };
        params[key] = value;
        setParameters(params);
    }
    useEffect(() => {
        setDefaultParams();
    }, [procedure]);

    const generateInputBox = (name, description, value) => {
        return (<Row>
            <Col>
                <OverlayTrigger overlay={renderTooltip(description)}>
                    <Form.Label>{name}</Form.Label>
                </OverlayTrigger>
            </Col>

            <Col>
                <Form.Control value={value} onChange={(e) => onChange(name, e.target.value)} />
            </Col>
            <Col>
            </Col>
        </Row>
        )
    }
    const renderTooltip = (value) => {
        return <Tooltip>{value || "No description"}</Tooltip>
    }

    if (isLoading)
        return <ProgressSpinner />
    else
        return (<div >
            <Modal.Header>
                <Modal.Title>Execution
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={styles.container}>
                    <DisposableAlert message={message} variant={isError ? "danger" : "success"} onClose={() => setMessage(null)} />
                    <Form>
                        {parameters && Object.keys(parameters).map((key) => {
                            return (<div>
                                {generateInputBox(key,
                                    procedure.parameters[key].description,
                                    parameters[key])
                                }
                                <hr style={styles.line} />
                            </div>)
                        })}
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={setDefaultParams}>Reset Defaults</Button>
                <Button variant="primary" onClick={executeProcedure}>Execute</Button>
            </Modal.Footer>
        </div>);
}

export default ExecutionParameters;