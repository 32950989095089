import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";
import ModalCommonDialog from "../../controls/ModalCommonDialog";
import { context } from "../context";
import insightsLogger from '../../../insightsLogger';
const ModalFileUpload = ({ onClose }) => {
    // console.log(progress)
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const { uploadFiles, path } = useContext(context);

    const onChange = event => {
        setFiles(event.target.files);
    }
    const handleClose = async (isCancel, selectedFiles) => {
        if (isValid(selectedFiles)) {
            insightsLogger.log('EXPLORER','FILE_UPLOAD');
           
            uploadFiles(files, path);
        }
        setError(null);
        setFiles(null);
        onClose();
    }
    const isValid = (files) => {
        if (!files || files.length === 0) {
            setError("Required");
            return false;
        }
        else {
            setError(null);
            return true;
        }
    }

    const onSubmit = () => {
        handleClose(false, files)
    }
    return (<ModalCommonDialog
        closeButton={false}
        heading={"File upload"}
        handleClose={() => handleClose(true, null)}
        handleOk={onSubmit}
        okButton="Upload"
        cancelButton="Close" >

        <Form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
            <Form.Group>< Form.Label > {'Select files to upload'}</Form.Label>
                <Form.Control multiple type="file" isInvalid={error} onChange={(e) => onChange(e)} />
                {error}
            </Form.Group>
        </Form >
    </ModalCommonDialog >);
}

export default ModalFileUpload