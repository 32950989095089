import React, { useState, useCallback } from "react";
import ModalCommonDialog from "../controls/ModalCommonDialog";
import { Form, Button, OverlayTrigger,Tooltip } from "react-bootstrap";
import DisposableAlert from "../controls/DisposableAlert";
import { addCustomerApi } from "../../actions/customers_actions";
import { logoutUser } from "../auth/Logout";
import config from "../../config/dataoceanConfig";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { styles } from "../../style/navbar/AddWorkspace.styles";
import insightsLogger from '../../insightsLogger';
const AddWorkspace = ({ userDetails, switchNewCustomer }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState(null);
    const [consumerKey, setConsumerId] = useState(null);
    const [consumerSecret, setConsumerSecret] = useState(null);

    const addWorkspace = async () => {

        setIsLoading(true);
        if (await validate()) {

            const { data, error } = await addCustomerApi(name, consumerKey, consumerSecret);
            if (data) {
                insightsLogger.log('WORKSPACE', 'ADD_WORKSPACE',{name});
                             
                clearControls();
                setMessage("Worspace added successfully");
                setError(false);
                switchNewCustomer(data.customerDetails.id);
            } else {
                setMessage(error);
                setError(true);
            }
        }

        setIsLoading(false);

    }
    const validate = async () => {
        let errorMsg;
        if (!name)
            errorMsg = "Name required";
        else if (!consumerKey)
            errorMsg = "Consumer Key required";
        else if (!consumerSecret)
            errorMsg = "Consumer Secret required";


        if (errorMsg) {
            setError(true);
            setMessage(errorMsg);
            return false;
        }
        else {
            setError(false);
            setMessage("");
            return true;
        }

    }

    const handleClose = () => {
        if (isActiveCustomerExist()) {
            clearControls();
            setShowDialog(false);
        } else {
            logoutUser();
        }
    }

    const clearControls = useCallback(() => {
        setMessage("");
        setName("");
        setConsumerId("");
        setConsumerSecret("");
    }, []);

    const generateInputBox = (name, value, handleChange, type) => {
        return (
            <Form.Group controlId={`frm${name}`}>
                <Form.Label>{name}</Form.Label>
                <Form.Control autoComplete="off" value={value} required type={type} onChange={(e) => handleChange(e.target.value)} />
            </Form.Group >
        )
    }

    const isActiveCustomerExist = () => {
        return (userDetails && userDetails.activeCustomer);
    }

    const renderTooltip = (value) => {
        return (<Tooltip>{value}</Tooltip>)
    }
    if (!isActiveCustomerExist() || showDialog)
        return (
            <ModalCommonDialog
                heading="Add Workspace"
                okButton="Add"
                cancelButton={isActiveCustomerExist() ? "Cancel" : "Logout "}
                handleOk={addWorkspace}
                handleClose={handleClose}
                isLoading={isLoading}
                disableOk={!(name && consumerKey && consumerSecret)
                }
                closeButton={false}
            >
                <Form onSubmit={(e) => { e.preventDefault(); addWorkspace(); }}>
                    <DisposableAlert dismissible={false} message={message} variant={isError ? "danger" : "success"}></DisposableAlert>
                    {generateInputBox("Name", name, setName, "name")}
                    <p>API Cloud Credentials
                       
                    <OverlayTrigger overlay={renderTooltip("Click here for more info")}>
                    <QuestionCircleFill  onClick={() => { window.open(config.Help_document_url) }} style={styles.helpButton}>
                           
                    </QuestionCircleFill>
                    </OverlayTrigger>
                    </p>
                    {generateInputBox("Consumer Key", consumerKey, setConsumerId, "consumerKey")}
                    {generateInputBox("Consumer Secret", consumerSecret, setConsumerSecret, "consumerSecret")}
                </Form>

            </ModalCommonDialog >
        );

    else
        return (
            <div style={styles.root}>
                <Button style={styles.button} variant="outline-dark"  onClick={() => setShowDialog(true)}>Add Workspace</Button>
            </div>
        )


}

export default AddWorkspace;