import React from "react";
import { Button, Modal } from "react-bootstrap";
import ProgressSpinner from "./ProgressSpinner";

const ModalCommonDialog = ({ heading, handleClose, handleOk, okButton = "Ok", cancelButton = "Cancel", children, isLoading = false, disableOk = false, closeButton = true }) => {
    return (<Modal centered show={true} onHide={closeButton ? handleClose : null}>
        <Modal.Header closeButton={closeButton} >
            <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isLoading && <ProgressSpinner />}
            {children}
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={isLoading} variant="outline-dark" onClick={handleClose}>
                {cancelButton}
            </Button>
            <Button type="submit" disabled={isLoading || disableOk} variant="primary" onClick={handleOk}>
                {okButton}
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default ModalCommonDialog