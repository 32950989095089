import React, { useContext } from 'react';
import { Breadcrumb } from "react-bootstrap";
import { context } from "./context";
import { withRouter } from 'react-router';

const PathBreadCrumbs = ({ history }) => {
    const { path } = useContext(context);

    const generateItem = ({ link, text }) => {
        return <Breadcrumb.Item key={text} onClick={() => link && history.push(link)} >{text}</Breadcrumb.Item>
    }
    const splitPath = () => {
        if (path && path.length > 1) {
            const paths = [{ link: "/", text: "Home" }];
            const subfolders = path.split('/');
            for (let index = 0; index < subfolders.length; index++) {
                const text = subfolders[index];
                const link = `?path=${subfolders.slice(0, index + 1).join('/')}`;
                if (index + 1 === subfolders.length)
                    paths.push({ text });
                else
                    paths.push({ text, link });
            }
            return paths;
        } else {
            return [{ text: "Home" }];
        }
    }

    return (<Breadcrumb>
        {splitPath().map(eachpath => {
            return generateItem(eachpath)
        })}
    </Breadcrumb>);
}


export default withRouter(PathBreadCrumbs);