import React from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { styles } from "../../style/explorer/ExplorerInfoPanel.styles";

const ExplorerInfoPanel = ({ properties, handleClose }) => {
    const constructProperties = (properties) => {
        if (properties) {
            const commonProps = [
                { key: "Id", value: properties.id },
                { key: "Path", value: properties.fullPath },
                { key: "Name", value: properties.name },
                { key: "Created", value: properties.created },
                { key: "Updated", value: properties.updated }
            ];
            if (properties.isDirectory) {
                return commonProps;
            } else {
                const fileProps = [
                    { key: "Status", value: properties.status },
                    { key: "Version", value: properties.version },
                    { key: "Size", value: properties.size },
                    { key: "Multipart", value: String(properties.multipart) },
                ]
                return commonProps.concat(fileProps);
            }
        }
        else {
            return [];
        }

    }
    const renderItems = (properties) => {
        return properties.map(property => {
            return renderItem(property);
        })
    };

    const renderItem = (property) => {
        return <Row key={property.key} >
            <Col xs={4}>
                <p className="body-text" >{property.key}</p>
            </Col>
            <Col xs={8} style={styles.propertyValue}>
                <p className="body-text" >{property.value}</p>
            </Col>
        </Row >
    };

    return (<div style={styles.root} >
        <Modal.Header closeButton onHide={handleClose} >
            <Modal.Title>Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <Container style={styles.body}>
                {renderItems(constructProperties(properties))}
            </Container>
        </Modal.Body>
    </div>

    );
}

export default ExplorerInfoPanel;