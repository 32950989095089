import axios from "axios";
import config from '../config';
import { getToken } from "../helpers/tokenHelper";
export const requestHandler = async (options) => {
    try {

        // console.log('requestHandler.start')
        const token = getToken();

        options.baseURL = config.ApiBaseUrl;
        options.headers = {
            Authorization: `Bearer ${token}`
        };

        //console.log(options);
        const response = await axios.request(options);
        //console.log(response.data);


        return response.data;
    }
    catch (error) {
        console.log('requestHandler.error')
        console.log(error);
        let errorMessage;
        if (error.response) {
            // client received an error response (5xx, 4xx)
            errorMessage = error.response.data.error || error.response.data.errors || error.response.data.message || error.response.data
        }
        else {
            errorMessage = error
        }
        if (errorMessage.Message)
            errorMessage = errorMessage.Message.toString()
        else if (errorMessage.message)
            errorMessage = errorMessage.message.toString()
        else if (errorMessage)
            errorMessage = errorMessage.toString();
        console.log(errorMessage);

        throw errorMessage;
        //todo: handle 401 unauthorized user redirection here OR THROW
    }
}

