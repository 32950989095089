import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { fetchExecutionDetails } from "../../actions/executions_action";
import ProgressSpinner from "../controls/ProgressSpinner";
import { styles } from "../../style/executions/ExecutionProperties.styles";
import config from "../../config";
const ExecutionProperties = ({ executionId, handleClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [executionDetails, setExecutionDetails] = useState(null);

    const fetchExecution = async () => {
        setIsLoading(true);
        const { data, error } = await fetchExecutionDetails(executionId);
        setExecutionDetails(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchExecution();
    }, [executionId]);
    const renderProperties = () => {
        if (!executionDetails)
            return null;
        return (<Container>
            {renderProperty("id", executionDetails.id)}
            {renderProperty("execution_status", executionDetails.execution_status)}
            {renderProperty("procedure_version", executionDetails.procedure_version)}
            {renderProperty("procedure_id", executionDetails.procedure_id)}
            {renderProperty("created_at", executionDetails.created_at)}
            {renderProperty("updated_at", executionDetails.updated_at)}
            {renderProperty("submitted_at", executionDetails.submitted_at)}
            {renderProperty("completed_at", executionDetails.completed_at)}
            {renderProperty("failed_at", executionDetails.failed_at)}

            {renderParameters()}
        </Container>
        );
    };
    const renderParameters = () => {
        const paramKeys = Object.keys(executionDetails.parameters);
        return (<div>
            <Row>
                <Modal.Title>
                    Parameters
                </Modal.Title>
            </Row>
            {paramKeys.map((key) => {
                const value = executionDetails.parameters[key];
                let link;
                if (value && value.includes(":/") && !value.includes(".")) {
                    const path = value.substring(value.indexOf(':') + 2, value.length);
                    link = `${config.WebAppUrl}?path=${path}`;
                }
                return renderProperty(key, value, link)
            })}
        </div>);
    }
    const renderProperty = (key, value, link = null) => {
        return (<Row key={key} >
            <Col xs={6} style={styles.property}>
                <p className="body-text" >{key}</p>
            </Col>
            <Col xs={6} style={styles.property}>
                {link ?
                    <a className="body-text" href={link} target="_blank">{value}</a>
                    : <p className="body-text">{value}</p>}

            </Col>
        </Row >);
    }
    return (<div style={styles.root}>
        <Modal.Header closeButton onHide={() => handleClose()}>
            <Modal.Title>
                Properties
         </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.body}>
            {isLoading ?
                <ProgressSpinner />
                : renderProperties()}
        </Modal.Body>
    </div>
    );
}

export default ExecutionProperties;