import React, { useState, useCallback } from "react";
import ModalCommonDialog from "../controls/ModalCommonDialog";
import DisposableAlert from "../controls/DisposableAlert";
import { addUser } from "../../actions/users_actions";
import { Form } from "react-bootstrap";
import insightsLogger from '../../insightsLogger';
const InviteUsers = ({ userDetails, inviteRoles }) => {
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [role, setRole] = useState(inviteRoles[0]);

    const addUserDetails = async () => {

        if (!validate())
            return;

        setIsLoading(true);
        const body = {
            customerId: userDetails.activeCustomer ? userDetails.activeCustomer.customerId : null,
            roleName: role,
            userDetails: [
                {
                    email,
                    firstName,
                }
            ]
        }
        const { data, error } = await addUser(body);
        if (data) {
            insightsLogger.log('WORKSPACE', 'INVITE_USER', {
                email,
                name: userDetails.activeCustomer.name,
                role
            });
            clearControls();
            setMessage("Invitation email sent successfully");
            setError(false);
        } else {
            setMessage(error);
            setError(true);
        }
        setIsLoading(false);

    }
    const validate = () => {
        var emailRE = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        let errorMsg;
        if (!emailRE.test(email))
            errorMsg = "Invalid email";
        else if (!firstName)
            errorMsg = "First name required";
        else if (!role)
            errorMsg = "Role required";

        if (errorMsg) {
            setError(true);
            setMessage(errorMsg);
            return false;
        }
        else {
            setError(false);
            setMessage("");
            return true;
        }
    }
    const generateInputBox = (name, value, handleChange, type) => {
        return (
            <Form.Group controlId={`frm${name}`}>
                <Form.Label>{name}</Form.Label>
                <Form.Control value={value} required type={type} onChange={(e) => handleChange(e.target.value)} />
            </Form.Group >
        )
    }

    const generateDropdown = (name, values, defaultValue, handleChange) => {
        return (
            <Form.Group controlId={`form${name}`}>
                <Form.Label>{name}</Form.Label>
                <Form.Control as={"select"} type={name} defaultValue={defaultValue} onChange={(e) => handleChange(e.target.value)} >
                    {
                        values.map((value) => {
                            return (<option>{value}</option>);
                        })
                    }
                </Form.Control>
            </Form.Group >)
    }
    const handleClose = () => {
        clearControls();
        setShowInviteDialog(false)
    };

    const clearControls = useCallback(() => {
        setMessage("");
        setEmail("");
        setFirstName("");
    }, []);

    return <div>
        {showInviteDialog &&
            <ModalCommonDialog
                heading="Invite User"
                handleClose={handleClose}
                handleOk={addUserDetails}
                isLoading={isLoading}
                okButton="Invite"
                disableOk={!(email && firstName && role)}
            >
                {<Form onSubmit={(e) => { e.preventDefault(); addUserDetails(); }}>
                    <DisposableAlert dismissible={false} message={message} variant={isError ? "danger" : "success"} onClose={() => { setMessage(null) }} />
                    {generateInputBox("Email", email, setEmail, "email")}
                    {generateInputBox("First name", firstName, setFirstName, "text")}
                    {generateDropdown("Role", inviteRoles, role, setRole)}
                </Form>}
            </ModalCommonDialog>}


        <div onClick={() => setShowInviteDialog(!showInviteDialog)}>
            Invite User
        </div>
    </div>

}

export default InviteUsers;