import React from "react";
import { FileEarmarkFill } from "react-bootstrap-icons";
import { styles } from "../../../style/explorer/files/FilesRow.styles";
import Actions from "../Actions";
import { formatBytes } from "../../../helpers/fileSizeFormatter";
const FilesRow = (files) => {
    return files ? files.map((file) => {
        const fullPath = file.path;
        const path = fullPath.substring(fullPath.indexOf(':') + 2, fullPath.length);
        const fileName = path.replace(/^.*[\\\/]/, '');
        const downloadUrl = file.download ? file.download.url : null;

        return {
            id: file.id,
            type: <FileEarmarkFill style={styles.fileIcon} />,
            isDirectory: false,
            path,
            fullPath,
            name: fileName,
            created: file.created_at,
            updated: file.updated_at,
            size: formatBytes(file.size),
            downloadUrl: downloadUrl ? encodeURIComponent(downloadUrl) : null,
            actions: <Actions downloadUrl={downloadUrl} name={fileName} id={file.id} isDirectory={false} />,
            version: file.version,
            status: file.status,
            multipart: file.multipart,

        }

    }) : [];
}

export default FilesRow;