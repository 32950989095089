import React from "react";
import { FolderFill } from "react-bootstrap-icons";
import { styles } from "../../../style/explorer/directories/DirectoriesRow.styles";
import Actions from "../Actions";
const DirectoriesRow = (directories) => {

    return directories ? directories.map((directory) => {
        const fullPath = directory.path;
        const path = fullPath.substring(fullPath.indexOf(':') + 2, fullPath.length);
        const directoryName = path.replace(/^.*[\\\/]/, '');

        return {
            id: directory.id,
            type: <FolderFill style={styles.directoryIcon} />,
            isDirectory: true,
            path,
            fullPath,
            name: directoryName,
            created: directory.created_at,
            updated: directory.updated_at,
            size: '-',
            actions: <Actions name={directoryName} id={directory.id} isDirectory={true} />
        }
    }) : [];
}

export default DirectoriesRow;