import React, { useState, useEffect } from 'react';
import qs from "query-string";
import ExplorerTable from "./ExplorerTable";
import PathBreadCrumbs from './PathBreadCrumbs';
import { listDirectories } from "../../actions/directories_action";
import { listFiles } from "../../actions/files_action";
import CreateDirectory from "./directories/CreateDirectory";
import DisposableAlert from '../controls/DisposableAlert';
import { Container, Row, Col } from "react-bootstrap";
import ProgressSpinner from '../controls/ProgressSpinner';
import FileUpload from './files/FileUpload';
import { styles } from "../../style/explorer/ExplorerLayout.styles";
import { context } from "./context";
import FilesUploadProgress from './files/FilesUploadProgress';

const ExplorerLayout = () => {
    // console.log('ExplorerLayout start');

    const values = qs.parse(window.location.search);
    const path = values.path || "/";
    // console.log('ExplorerLayout path' + path);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState(null);
    const [directories, setDirectories] = useState(null);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(null);

    const [uploadableFiles, setUploadableFiles] = useState([]);

    const fetchDirectoriesAndFiles = async () => {
        // console.log('ExplorerLayout.fetchDirectoriesAndFiles start')
        setIsLoading(true);

        let [filesResult, directoriesResult] = await Promise.all([
            listFiles(path),
            listDirectories(path)
        ])

        setFiles(filesResult.data);
        setDirectories(directoriesResult.data);

        if (directoriesResult.error || filesResult.error)
            updateMessage(directoriesResult.error || filesResult.error, true)

        setIsLoading(false);
    }

    const callback = async (message, isError) => {

        if (!isError) {
            setDirectories(null);
            setFiles(null);
            await fetchDirectoriesAndFiles()
        }

        updateMessage(message, isError);
    }

    const updateMessage = (message, isError) => {
        setMessage(message);
        setIsError(isError);
    }



    useEffect(() => {
        // console.log('DirectoriesLayout.useEffect start')
        fetchDirectoriesAndFiles();

    }, [path])


    return (<context.Provider value={{
        refreshCallback: (message, isError) => { callback(message, isError) },
        path: path,
        message: message,
        uploadFiles: (selectedFiles) => { setUploadableFiles([...uploadableFiles, ...selectedFiles]) },
        clearUploadedFiles: () => { setUploadableFiles([]) }
    }}>
        {isLoading && <ProgressSpinner />}

        <Container style={styles.root} >
            <Row style={styles.containerItems}>
                <DisposableAlert variant={isError ? "danger" : "success"} message={message} onClose={() => setMessage(null)} />
            </Row>
            <Row style={styles.containerItems}>
                <Col >
                    <Container>
                        <Row>
                            <PathBreadCrumbs />
                        </Row>
                    </Container>
                </Col>
                <Col >
                    <Container style={styles.buttonContainer}>
                        <Row>
                            <Col >
                                <CreateDirectory />
                            </Col>
                            <Col >
                                <FileUpload />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row style={styles.containerItems}>
                <ExplorerTable directories={directories} files={files} />
            </Row>
            <Row>
                <FilesUploadProgress files={uploadableFiles} />
            </Row>
        </Container>

    </context.Provider >)
}

export default ExplorerLayout;