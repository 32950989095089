import React from "react";
import { Alert } from "react-bootstrap";
import { styles } from '../../style/controls/DisposableAlert.styles';
const DisposableAlert = ({ variant, message, dismissible = true, onClose }) => {
    if (message)
        return (<Alert dismissible={dismissible} onClose={() => onClose()} variant={variant} style={styles.root}>
            <Alert.Heading>{message}</Alert.Heading>
        </Alert >)
    else
        return <div />
}

export default DisposableAlert;