import React from "react";
import { getToken } from "../../helpers/tokenHelper";
import ProgressSpinner from "../controls/ProgressSpinner";

const Authenticator = ({ getComponent }) => {
    //console.log('Authenticator')

    const token = getToken();

    if (token)
        return getComponent();
    else
        return <ProgressSpinner />
}

export default Authenticator;