export const styles = {
    container: {
        height: "55vh",
        overflowX: "auto",
        overflowY: "auto"
    },
    line: {
        margin: "5px 0px 5px 0px"
    }

}