export const styles = {
    root: {
        border: "solid 1px #d7d7db"
    },
    body: {
        height: "80vh",
        overflowX: "auto",
        overflowY: "auto"
    },
    property: {
        overflowWrap: "break-word"
    }
}