import { requestHandler } from './axiosClient';
import { UMS } from "../config/apiPaths";


export const addCustomerApi = async (workspace, clientId, clientSecret) => {

    try {
        // console.log('actions.addCustomer');

        let options = {
            url: UMS.ValidateCustomerConfig,
            method: 'POST',
            data: {
                clientId,
                clientSecret
            }
        }

        await requestHandler(options);

        options = {
            url: UMS.AddCustomer,
            method: 'POST',
            data: {
                customerName: workspace
            }
        }

        const customer = await requestHandler(options);

        options = {
            url: UMS.UpsertCustomerConfig,
            method: 'PUT',
            data: {
                customerId: customer.customerDetails.id,
                clientId,
                clientSecret
            }
        }

        await requestHandler(options);
        return { data: customer }

    }
    catch (error) {
        return { error }
    }
};



export const ValidateCustomerConfigApi = async (clientId, clientSecret) => {

    try {
        // console.log('actions.addCustomerConfig');
        const options = {
            url: UMS.ValidateCustomerConfig,
            method: 'POST',
            data: {
                clientId,
                clientSecret
            }
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }
};
