import React from 'react';
import './App.css';
import NavigationBar from "./components/navbar/NavigationBar";
import About from './About';

function App({ children }) {
  return (
    <div>
      <NavigationBar />
      {children}
      <About />
    </div>
  );
}

export default App;
