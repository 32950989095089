import React from "react";
import ModalCommonDialog from "./ModalCommonDialog";

const ModalConfirmation = ({ heading, body, okButtonText, handleClose, isLoading = false }) => {
    // console.log('ModalConfirmation start');

    return (<ModalCommonDialog
        heading={heading}
        handleClose={() => handleClose(true)}
        handleOk={() => handleClose(false)}
        isLoading={isLoading}
        okButton={okButtonText}>

        {body}

    </ModalCommonDialog >);

}
export default ModalConfirmation