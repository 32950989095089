export const styles = {
    root: {
        maxWidth: "100%",
        padding: "10px 0px 0px 0px",
    },
    containerItems: {
        margin: 0
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
}