import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ModalCommonDialog from "./ModalCommonDialog";

const ModalInputBox = ({ heading, body, onClose, error, onValidate, isLoading }) => {
    const [inputValue, setInputValue] = useState(null);

    const onChange = event => {
        const updatedValue = event.target.value.trim();
        setInputValue(updatedValue);
        onValidate(updatedValue);
    }
    const onSubmit = () => {
        onClose(false, inputValue)
    }
    return (<ModalCommonDialog
        heading={heading}
        handleClose={() => onClose(true, "")}
        handleOk={onSubmit}
        isLoading={isLoading}>

        <Form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
            <Form.Group >
                <Form.Label> {body}</Form.Label>
                <Form.Control placeholder={body} isInvalid={error} onChange={(e) => onChange(e)} />
                {error}
            </Form.Group>
        </Form>

    </ModalCommonDialog >);
}

export default ModalInputBox