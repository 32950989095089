export const styles = {
    root: {
        padding: "0px 7px 0px 7px"
    },
    button: {
        width: "100%"
    }, 
	helpButton: {
        padding: "0px 0px 3px 5px ",
        height: 20,
        width: 20
    }

}