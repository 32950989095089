import React, { useContext } from "react";
import IndividualFileUpload from "./IndividualFileUpload";
import { context } from "../context";
import { Modal } from "react-bootstrap";
import { styles } from "../../../style/explorer/files/FilesUploadProgress.styles";
const FilesUploadProgress = ({ files }) => {

    const { clearUploadedFiles } = useContext(context);
    const canClose = (files) => {
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            if (!file.isCompleted)
                return false;
        }
        return true;
    }

    const renderFiles = () => {
        return (
            <div style={styles.uploads}>
                {(files) && [...files].map(file => {
                    return <IndividualFileUpload key={file.name} file={file} />
                })}
            </div>
        );
    }

    const onClose = () => {
        clearUploadedFiles()
    }

    if (files && files.length !== 0)
        return (<div style={styles.root}>
            <Modal.Header closeButton={canClose(files)} onHide={onClose}>
                <Modal.Title>
                    Uploads
                </Modal.Title>
            </Modal.Header>
            {renderFiles()}
        </div >
        );
    else
        return <div />
}

export default FilesUploadProgress