import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup, Modal } from "react-bootstrap";
import ProgressSpinner from "../controls/ProgressSpinner";
import { fetchAllProcedure } from "../../actions/procedures_action";
import ProcedureDetails from "./ProcedureDetails";
import { styles } from "../../style/procedures/ProcedureLayout.styles";
import insightsLogger from '../../insightsLogger';
const ProcedureLayout = ({ }) => {
    const [procedures, setProcedures] = useState([]);
    const [selectedProcedure, setSelectedProcedure] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadProcedures = async () => {
        setIsLoading(true);
        const { data, error } = await fetchAllProcedure();
        if (data) {

            insightsLogger.log('PROCEDURES', 'FETCH_ALL');
            const sortedData = data.sort((a, b) => {
                a = a.name ? a.name.toLowerCase() : "no_name";
                b = b.name ? b.name.toLowerCase() : "no_name";
                if (a === b) return 0;
                if (a > b) return 1;
                return -1;
            });
            setProcedures(sortedData);
        }

        setIsLoading(false);
    }
    const renderProcedures = () => {
        return (<div style={styles.root}>
            <Modal.Header>
                <Modal.Title>Procedures</Modal.Title>
            </Modal.Header>
            <Modal.Body style={styles.body}>
                <ListGroup style={styles.list}>
                    {procedures.map(procedure => {
                        return <ListGroup.Item action variant="light" onClick={() => { setSelectedProcedure(procedure) }}>{`${procedure.name || "no_name"} - v${procedure.version}`}</ListGroup.Item>
                    })}
                </ListGroup>
            </Modal.Body>
        </div>)
    };

    useEffect(() => {
        loadProcedures();
    }, []);

    if (isLoading)
        return <ProgressSpinner />
    else
        return (<Container fluid >
            <Row >
                <Col xs={4}>
                    {renderProcedures()}
                </Col>
                <Col xs={8}>
                    <ProcedureDetails procedureId={selectedProcedure ? selectedProcedure.id : null}></ProcedureDetails>
                </Col>
            </Row>
        </Container>);
}

export default ProcedureLayout;