import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, DropdownButton, Dropdown } from "react-bootstrap";
import { getUserDetails, switchCustomer } from '../../actions/users_actions'
import ProgressSpinner from '../controls/ProgressSpinner';
import { PersonLinesFill } from "react-bootstrap-icons";
import { styles } from "../../style/navbar/NavigationBar.styles";
import InviteUsers from './InviteUsers';
import Logout from '../auth/Logout';
import config from "../../config";
import AddWorkspace from './AddWorkspace';
import insightsLogger from '../../insightsLogger';
import { Link } from 'react-router-dom';
const NavigationBar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [inviteRoles, setInviteRoles] = useState(null);

    const fetchUserDetails = async () => {
        setIsLoading(true);

        const { data } = await getUserDetails();
        if (data) {
            insightsLogger.log(
                'SESSION',
                'LOGIN',
                {
                    email: data.email,
                    workspace: data.activeCustomer ? data.activeCustomer.name : ""
                }
            )
        }
        setUserDetails(data);
        roles(data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const roles = (userInfo) => {
        const roleName = (userInfo && userInfo.activeCustomer) ? userInfo.activeCustomer.roleName : null
        let roles;
        if (roleName) {
            switch (roleName) {
                case "ADMIN":
                    roles = ["ADMIN", "USER"];
                    break;
                case "SUPER_ADMIN":
                    roles = ["SUPER_ADMIN"];
                    break;
                default:
                    roles = null;
                    break;
            }
        }

        setInviteRoles(roles);
    }
    const renderWorspaces = (userDetails) => {
        // console.log(userDetails)
        if (!userDetails)
            return
        if (!(userDetails.customers && userDetails.activeCustomer))
            return <AddWorkspace userDetails={userDetails} switchNewCustomer={switchNewCustomer} />

        const activeCustomer = userDetails.activeCustomer;
        return (<DropdownButton title={<span >{`${activeCustomer.name}  `}</span>}>

            {userDetails.customers.map(customer => {

                return <Dropdown.Item key={customer.name}
                    as="button"
                    active={customer.customerId === activeCustomer.customerId}
                    onClick={() => switchNewCustomer(customer.customerId)}
                >
                    {customer.name}
                </Dropdown.Item>
            })}
            <Dropdown.Divider />
            <AddWorkspace userDetails={userDetails} switchNewCustomer={switchNewCustomer} />
        </DropdownButton>)

    }
    const renderUserProfile = (userDetails) => {
        if (!userDetails)
            return;

        return (<NavDropdown alignRight title={<PersonLinesFill style={styles.profileIcon} />}>
            {isLoading && <ProgressSpinner />}

            <NavDropdown.Item disabled>Logged in as</NavDropdown.Item>
            <NavDropdown.Item disabled><h4>{`${userDetails.firstName} ${userDetails.lastName}`} </h4></NavDropdown.Item>
            <NavDropdown.Item disabled>{`${userDetails.email}`}</NavDropdown.Item>
            <NavDropdown.Divider />
            {inviteRoles && <div>
                <NavDropdown.Item><InviteUsers userDetails={userDetails} inviteRoles={inviteRoles} /></NavDropdown.Item>
                <NavDropdown.Divider />
            </div>}
            <NavDropdown.Item><Logout /></NavDropdown.Item>
        </NavDropdown >)
    }
    const switchNewCustomer = async (customerId) => {
        setIsLoading(true);
        await switchCustomer(customerId);
        window.open(config.WebAppUrl, "_self");
        setIsLoading(false);
    }
    const pathName = window.location.pathname;
    return (<Navbar sticky='top' variant="dark" bg="primary" expand="lg"  >
        <Navbar.Brand href="/" variant="light">
            <span><img alt="logo" src={window.location.origin + "/trimble_logo.svg"} />{' Trimble Drop'}</span>
        </Navbar.Brand >

        <Nav className="justify-content-end">
            <Nav.Link as={Link} active={pathName === "/"} to="/">Home</Nav.Link>
            <Nav.Link as={Link} active={pathName === "/procedures"} to="/procedures">Procedures</Nav.Link>
            <Nav.Link as={Link} active={pathName === "/executions"} to="/executions">Executions</Nav.Link>
        </Nav>
        {renderWorspaces(userDetails)}


        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" >
                {renderUserProfile(userDetails)}
            </Nav>
        </Navbar.Collapse>
    </Navbar >
    );
}

export default NavigationBar;