import React, { useState, useContext } from 'react';
import { Button } from "react-bootstrap";
import ModalInputBox from "../../controls/ModalInputBox";
import { createDirectory } from "../../../actions/directories_action";
import { context } from "../context";

const CreateDirectory = () => {

    // console.log('CreateDirectory start');
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { refreshCallback, path } = useContext(context);

    const isValidName = (updatedText) => {
        // console.log("validation")
        if (!updatedText) {
            setError("Required");
            return false;

        } else if (!(/^[0-9a-zA-Z ... ]+$/.test(updatedText))) {
            setError("Invalid characters");
            return false;
        }
        else {
            setError(null);
            return true;
        }
    }

    const handleClose = async (isCancel, name) => {
        // console.log('CreateDirectory.handleClose start')
        if (isCancel) {
            setShowDialog(false)
            setError(null);
        }
        else if (isValidName(name)) {

            if (await createDirectoryApi(name)) {
                setShowDialog(false)
                await refreshCallback("Directory created successfully", false)
            }
        }
    }
    const createDirectoryApi = async (name) => {

        setIsLoading(true);
        const fullPath = `${path}/${name}`;
        const result = await createDirectory(fullPath);
        setError(result.error)

        setIsLoading(false);

        return result.error ? false : true
    }

    if (!showDialog)
        return <Button onClick={() => setShowDialog(true)}>Create Directory</Button>
    else
        return <ModalInputBox
            isLoading={isLoading}
            heading="Create Directory"
            body='Enter Directory name'
            onClose={handleClose}
            onValidate={isValidName}
            error={error}
        />
}

export default CreateDirectory;