import React, { useState, useEffect, useContext } from 'react';
import Axios from "axios";
import { uploadFile, getUploadSignedURL, deleteFile } from "../../../actions/files_action";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { formatBytes } from "../../../helpers/fileSizeFormatter";
import { context } from "../context";
const IndividualFileUpload = ({ file }) => {
    // console.log('FileUploadProgress start');

    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [cancelTokenSource] = useState(Axios.CancelToken.source());
    const [fileId, setFileId] = useState(null);
    const { refreshCallback, path } = useContext(context);

    const fileUploadApi = async (directoryPath, file) => {
        const signedUrlResponse = await getUploadSignedURL(directoryPath, file);
        let apiError = signedUrlResponse.error;

        if (!apiError) {
            setFileId(signedUrlResponse.data.file.id);

            const uploadResponse = await uploadFile(signedUrlResponse.data, file, progressBar, cancelTokenSource);
            apiError = uploadResponse.error;

            if (apiError)
                await deleteFile(signedUrlResponse.data.file.id);
            setFileId(null);
        }

        if (apiError)
            setError(apiError);

        file.isCompleted = true;
        setProgress(100);
        refreshCallback();
    }
    const progressBar = ({ name, value }) => {
        setProgress(value);
    }

    const progressBarVariant = () => {
        if (error)
            return "danger";
        else if (progress === 100)
            return "success";
        else
            return "info";
    }
    const cancelUpload = async () => {
        setProgress(100);
        file.isCancelled = true;

        if (cancelTokenSource)
            cancelTokenSource.cancel("User Aborted");

        if (fileId)
            await deleteFile(fileId);
    }

    useEffect(() => {
        fileUploadApi(path, file);

        return (() => {
            cancelUpload();
        });
    }, []);

    return (<Container >
        <Row >
            <Col xs={11} >
                <p> {file.name}</p>
            </Col>
            <Col xs={1}>
                {(progress < 100) &&
                    <button type="button" className="close" aria-hidden="true" onClick={cancelUpload} >&times;</button>}
            </Col>
        </Row >
        <Row >
            <Col>
                <ProgressBar variant={progressBarVariant()} striped min={0} max={100} now={progress} label={error ? error : `${progress}% of ${formatBytes(file.size)}`} />
            </Col>
        </Row>
        <Row >

            <Col>
                <hr style={{ height: 1, marginTop: 10, marginBottom: 5 }} />
            </Col>
        </Row >
    </Container >)
}

export default IndividualFileUpload;