import config from "./index";

export const DataOceanV3 = {
    Directories: {
        DirectoriesList: "/api/3.0/directories/directories",
        Directories: "/api/3.0/directories"
    },
    Files: {
        FilesList: "/api/3.0/directories/files",
        Files: "/api/3.0/files",
        MultiPartAssemble: "api/3.0/files/:fileId/assemble"
    }
}
export const ProcessingFramework = {
    Procedures: "/api/1/api/procedures",
    Executions: "/api/1/api/executions",
    ExecutionEvents: "/api/1/api/executions/:executionId/events",
    ExecutionActivities: "/api/1/api/executions/:executionId/activities"
}

export const UMS = {
    Login: `/api/auth/login?redirect_uri=${config.WebAppUrl}/redirect`,
    Logout: "/api/auth/logout",
    UserDetails: "/api/users/me",
    AddUsers: "/api/users",
    SwithCustomer: "/api/users/switchcustomer",
    AddCustomer: "/api/customers",
    UpsertCustomerConfig: "/api/customerConfig",
    ValidateCustomerConfig: "/api/customerConfig/validate"
}
export const WebApp = {
    Previewer: '/previewer'
}
