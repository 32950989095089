import { requestHandler } from './axiosClient';
import { DataOceanV3 } from "../config/apiPaths";
import dataoceanConfig from "../config/dataoceanConfig";
export const listDirectories = async (
    path,
    limit = dataoceanConfig.paginationLimit,
    ascend = dataoceanConfig.sortOrder,
    next_page_token) => {

    try {
        // console.log('actions.listDirectories');
        const options = {
            url: DataOceanV3.Directories.DirectoriesList,
            method: 'GET',
            params: {
                path,
                limit,
                ascend,
                next_page_token
            }
        }

        const response = await requestHandler(options);
        return { data: response.directories }
    }
    catch (error) {
        return { error }
    }
};
export const createDirectory = async (path) => {
    try {
        // console.log('actions.createDirectory');
        const options = {
            url: DataOceanV3.Directories.Directories,
            method: 'POST',
            data: {
                directory: {
                    path
                }
            }
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }

};
export const deleteDirectory = async (directoryId) => {
    try {
        // console.log('actions.deleteDirectory');
        const options = {
            url: `${DataOceanV3.Directories.Directories}/${directoryId}?recursive=true`,
            method: 'DELETE'
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }

};