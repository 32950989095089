export const styles = {
    button: {
        padding: "5px 0px 0px 5px"
    },
    icon: {
        height: 20,
        width: 20
    },
    content: {
        height: "70vh",
        overflowX: "auto",
        overflowY: "auto"
    },
    jsonContent: {
        "white-space": "break-spaces"
    }
}