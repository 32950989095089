import config from "./config";
function InitLogger() {
  window.tdcafLogger.init({
    url: `https://ltdrkxdy16.execute-api.us-east-2.amazonaws.com/prod/datalogger`,
    headers: {
      'x-app-name': config.insights.appname,
      'x-api-key': config.insights.apikey
    },
    interval: 5000,
    trackEvents: {}
  });
  window.tdcafLogger.trackEvents();
  return null;
}

export default InitLogger;