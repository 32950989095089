import React, {  useState } from 'react';
import { Button } from "react-bootstrap";
import ModalFileUpload from './ModalFileUpload';

const FileUpload = () => {
    // console.log('FileUpload start');
    const [showDialog, setShowDialog] = useState(false);
    
    if (!showDialog)
        return <Button onClick={() => setShowDialog(true)}>Upload File</Button>
    else
        return <ModalFileUpload onClose={() => setShowDialog(false)} />
}

export default FileUpload;