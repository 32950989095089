import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Authenticator from "./components/auth/Authenticator";
import LoginSuccessRedirect from "./components/auth/LoginSuccessRedirect";
import LoginFailure from "./components/auth/LoginFailure";
import InitLogger from './InitLogger';

import Previewer from './Previewer';
import ExplorerLayout from './components/explorer/ExplorerLayout';
import ProcedureLayout from './components/procedures/ProcedureLayout';
import ExecutionsLayout from './components/executions/ExecutionsLayout';

ReactDOM.render(
  <React.StrictMode>
    {(process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "stg") && <InitLogger />}
    <BrowserRouter>
      <Switch>
        <Route path="/redirect" render={routeProps => <LoginSuccessRedirect {...routeProps} />} />
        <Route path="/loginfailure" render={routeProps => <LoginFailure {...routeProps} />} />
        <Route path="/previewer" render={routeProps => <Authenticator {...routeProps} getComponent={() => <App ><Previewer /></App>} />} />
        <Route path="/procedures" render={routeProps => <Authenticator {...routeProps} getComponent={() => <App ><ProcedureLayout /></App>} />} />
        <Route path="/executions" render={routeProps => <Authenticator {...routeProps} getComponent={() => <App ><ExecutionsLayout /></App>} />} />
        <Route path="/" render={routeProps => <Authenticator {...routeProps} getComponent={() => <App ><ExplorerLayout /></App>} />} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
