import React from "react";
import * as queryString from 'query-string';
import { Redirect } from "react-router";
import { setToken } from "../../helpers/tokenHelper";
const LoginSuccessRedirect = () => {

    console.log('LoginSuccessRedirect')

    const hash = window.location.hash;
    const tokenInfo = queryString.parse(hash);
    if (hash && tokenInfo) {
        setToken(tokenInfo)
        return <Redirect to="/" />
    }
    else {
        return <Redirect to="/loginfailure" />
    }
}

export default LoginSuccessRedirect;