import React from "react";
import { removeToken, getTokenInfo } from "../../helpers/tokenHelper";
import { logout } from "../../actions/users_actions";
import config from "../../config";

export const logoutUser = async () => {
    console.log("logout");
    await logout();
    const tokenInfo = getTokenInfo()
    removeToken();
    if (tokenInfo?.id_token) {
        const redirectUrl = config.TidLogoutUrl.replace("{id_token}", tokenInfo?.id_token).replace("{post_logout_redirect_uri}", config.WebAppUrl)
        window.open(redirectUrl, "_self");
    }
}
const Logout = () => {


    return <div onClick={logoutUser}>Logout</div>
}

export default Logout;