import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { fetchAllExecutions } from "../../actions/executions_action";
import ProgressSpinner from "../controls/ProgressSpinner";
import ExecutionProperties from "./ExecutionProperties";
import { styles } from "../../style/executions/ExecutionsLayout.styles";
import ExecutionDetails from "./ExecutionDetails";
import insightsLogger from '../../insightsLogger';

const ExecutionsLayout = () => {
    const [executions, setExecutions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const [selectedExecutionId, setSelectedExecutionId] = useState(null);

    const fetchExecutions = async () => {
        setIsLoading(true);
        const { data, error } = await fetchAllExecutions();
        if (data) {
            insightsLogger.log('EXECUTIONS','FETCH_ALL');
            setExecutions(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchExecutions();
    }, []);

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setSelectedExecutionId(row.id);
            if (!showInfoPanel)
                setShowInfoPanel(true);
        }

    };
    if (isLoading)
        return <ProgressSpinner />
    else
        return (<Container fluid style={styles.root}>
            <Row>
                <Col xs={showInfoPanel ? 9 : 12}>

                    <div style={styles.table}>
                        <BootstrapTable
                            const rowEvents={rowEvents}
                            hover
                            bootstrap4
                            striped={false}
                            bordered={false}
                            keyField="id"
                            columns={columns}
                            data={executions}
                        >

                        </BootstrapTable>
                    </div>
                </Col>
                {showInfoPanel && <Col xs={3} >
                    <ExecutionProperties executionId={selectedExecutionId} handleClose={() => setShowInfoPanel(false)} />

                </Col>}
            </Row>
        </Container>);
}

const columns = [
    {
        dataField: "id",
        text: "Execution Id",
        sort: true
    },
    {
        dataField: "execution_status",
        text: "Execution Status",
        sort: true,
        formatter: (cell, row) => {
            let color = "black";
            if (cell === "FAILED")
                color = "red"
            else if (cell == "FINISHED")
                color = "green"
            return <div style={{ color }}>{cell}</div>
        }
    },
    {
        dataField: "procedure_identifier",
        text: "Procedure Identifier",
        sort: true
    },
    {
        dataField: "procedure_version",
        text: "Version",
        sort: true,
        hidden: true
    },
    {
        dataField: "procedure_id",
        text: "Procedure Id",
        sort: true
    }, {
        dataField: 'actions',
        text: 'Actions',
        headerStyle: { width: 100, textAlign: 'center' },
        formatter: (cell, row) => {
            return <ExecutionDetails executionId={row.id} />
        }
    }
]
export default ExecutionsLayout;