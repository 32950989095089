export const styles = {
    root: {
        border: "solid 1px #d7d7db"
    },
    body: {
        height: "80vh"
    },
    list: {
        maxHeight: "75vh",
        overflowX: "auto",
        overflowY: "auto"
    }

}