//CHUNK_SIZE should be greater than 5MB a   nd less than 5GB
const dataoceanConfig = {
    paginationLimit: 1000,
    sortOrder: 0,
    url_duration: '30d',
    CHUNK_SIZE: 1024 * 1024 * 100,
    MULTIPART_SIZE: 1024 * 1024 * 200,
    Help_document_url: "https://docs.google.com/document/d/1gqB2pjJd6vTtnLfqq4JdG3RgAdNii-VjujCu8poIr7E/edit?usp=sharing"
}

export default dataoceanConfig;