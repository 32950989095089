export const styles = {
    root: {
        alignItems: "center",
        border: "solid 1px #d7d7db",
        backgroundColor: "white",
        position: "absolute",
        bottom: 50,
        right: 15,
        height: 300,
        width: 450
    },
    uploads: {
        height: 240,
        overflowX: "auto",
        overflowY: "auto"
    }
}