import { UMS } from "../config/apiPaths";
import config from "../config";

const tokenKey = "tokenInfo";

export const getToken = () => {
    const tokenInfo = getTokenInfo();
    if (tokenInfo) {
        return tokenInfo.access_token;
    }
    redirectLogin();
    return null;
}
export const getTokenInfo = () => {
    console.log('getTokenFromLocalStorage')

    const tokenInfoString = localStorage.getItem(tokenKey);
    if (tokenInfoString) {
        const tokenInfo = JSON.parse(tokenInfoString);

        if (+new Date() < tokenInfo.expires_at) {
            return tokenInfo;
        }
    }
    redirectLogin();
    return null;
}
const redirectLogin = () => {
    const loginUrl = config.ApiBaseUrl + UMS.Login;
    window.open(loginUrl, "_self");
}

export const setToken = (tokenInfo) => {
    // console.log('addTokenToStorage');

    tokenInfo.expires_at = +new Date() + Number(tokenInfo.expires_in);
    localStorage.setItem(tokenKey, JSON.stringify(tokenInfo))
}
export const removeToken = () => {
    localStorage.removeItem(tokenKey);
}

