import { requestHandler } from './axiosClient';
import { ProcessingFramework } from "../config/apiPaths";
import dataoceanConfig from "../config/dataoceanConfig";
export const fetchAllProcedure = async (per_page = dataoceanConfig.paginationLimit
) => {
    try {
        // console.log('actions.fetchAllProcedure');
        const options = {
            url: ProcessingFramework.Procedures,
            method: 'GET',
            params: {
                per_page,
            }
        }

        const response = await requestHandler(options);
        return { data: response.items }
    }
    catch (error) {
        return { error }
    }
};
export const fetchProcedureDetails = async (
    procedureId) => {
    try {
        // console.log('actions.fetchProcedureDetails');
        const options = {
            url: `${ProcessingFramework.Procedures}/${procedureId}`,
            method: 'GET',
        }

        const response = await requestHandler(options);
        return { data: response.procedure }
    }
    catch (error) {
        return { error }
    }
};