import React, { useState, useEffect } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import ProgressSpinner from "../controls/ProgressSpinner";
import { fetchProcedureDetails } from "../../actions/procedures_action";
import ExecutionParameters from "./ExecutionParameters";
import insightsLogger from '../../insightsLogger';
const ProcedureDetails = ({ procedureId }) => {
    const [procedure, setProcedure] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadProceduresDetails = async () => {
        setIsLoading(true);
        if (procedureId) {
            const { data, error } = await fetchProcedureDetails(procedureId);
            if (data) {
                insightsLogger.log('PROCEDURES', 'FETCH_PROCEDURE', data);
                setProcedure(data);
            }
        }
        setIsLoading(false);
    }

    const generateInputBox = (name, value) => {
        return (<Col>
            <Form.Group controlId={`frm${name}`}>
                <Form.Label>{name}</Form.Label>
                <Form.Control disabled autoComplete="off" value={value} />
            </Form.Group >
        </Col>
        )
    }
    const generateProperties = () => {
        return (<Form>
            <Container>
                <Row>
                    {generateInputBox("Name", procedure.name)}
                    {generateInputBox("Id", procedure.id)}
                    {generateInputBox("Version", procedure.version)}
                    {generateInputBox("Description", procedure.description)}
                </Row>
            </Container>
        </Form>);
    }

    useEffect(() => {
        loadProceduresDetails();
    }, [procedureId]);

    if (isLoading)
        return <ProgressSpinner />
    else
        return (<div>
            <Modal.Header><Modal.Title>Procedure Details</Modal.Title></Modal.Header>
            <Modal.Body>
                {procedure ? (<div>
                    {generateProperties()}
                    <ExecutionParameters procedure={procedure} />
                </div>)
                    : <div>Select procedure from the left panel</div>}
            </Modal.Body>
        </div >);
}

export default ProcedureDetails;