import React, { useEffect, useState } from "react";
import { fetchExecutionActivities, fetchExecutionEvents } from "../../actions/executions_action";
import ModalCommonDialog from "../controls/ModalCommonDialog";
import { Button, OverlayTrigger, Tooltip, Tabs, Tab } from "react-bootstrap";
import { Bug } from "react-bootstrap-icons";
import { styles } from "../../style/executions/ExecutionDetails.styles";
const ExecutionDetails = ({ executionId }) => {
    const [activities, setActivities] = useState(null);
    const [events, setEvents] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("Activities");
    const [openDialog, setOpenDialog] = useState(false);

    const fetchLogs = async () => {

        if (executionId) {
            setIsLoading(true);

            setActivities(null);
            setEvents(null);
            const [activitiesResponse, eventsReponse] = await Promise.all([
                await fetchExecutionActivities(executionId),
                await fetchExecutionEvents(executionId)
            ]);

            setActivities(activitiesResponse.error || activitiesResponse.data);
            setEvents(eventsReponse.error || eventsReponse.data);

            setIsLoading(false);
        }
    }

    const renderTooltip = (value) => {
        return (<Tooltip>{value}</Tooltip>)
    }
    const renderJson = (data) => {
        if (data)
            return (<pre  style={styles.jsonContent}> {JSON.stringify(data, null, 2)}</pre>);
    }

    useEffect(() => {
        if (openDialog) {
            fetchLogs();
        }

    }, [openDialog, executionId]);

    if (!openDialog) {
        return (<OverlayTrigger overlay={renderTooltip("Debug logs")}>
            <Button variant="outline" style={styles.button}>
                <Bug style={styles.icon} onClick={() => { setOpenDialog(true) }} />
            </Button>
        </OverlayTrigger>);

    }
    else {
        return (<ModalCommonDialog
            heading={"Logs"}
            okButton={"Refresh"}
            cancelButton={"Close"}
            handleOk={() => fetchLogs()}
            handleClose={() => setOpenDialog(false)}
            isLoading={isLoading}
        >

            <Tabs
                activeKey={activeTab}
                onSelect={(k => setActiveTab(k))}
                id="ExecutionDetails"
            >
                <Tab style={styles.content}
                    eventKey="Activities"
                    title="Activities"
                >
                    {renderJson(activities)}
                </Tab>
                <Tab style={styles.content}
                    eventKey="Events"
                    title="Events"
                >
                    {renderJson(events)}
                </Tab>

            </Tabs>

        </ModalCommonDialog>);

    }
}

export default ExecutionDetails;