import React from 'react';
import FileViewer from 'react-file-viewer';
import * as qs from "query-string";
const Previewer = () => {
    const query = qs.parse(window.location.search);
    console.log(query);
    if (query) {
        const filepath = query.filepath;
        const filename = query.filename;

        if (filepath && filename) {
            const index = filename.lastIndexOf(".");
            const filetype = filename.slice(index + 1);

            console.log(filepath);
            console.log(filename);
            console.log(filetype);

            return (<div>
                {/* <h1>{filename}</h1> */}
                <FileViewer fileType={filetype}
                    filePath={filepath} />
            </div>
            );
        }
    }
    return (<div>Please pass params as Eg: ?filepath="url"&filename="sample.xlsx"</div>);

}

export default Previewer;