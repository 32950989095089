import axios from "axios";
import { requestHandler } from './axiosClient';
import { DataOceanV3 } from "../config/apiPaths";
import dataoceanConfig from "../config/dataoceanConfig";

const CHUNK_SIZE = dataoceanConfig.CHUNK_SIZE;
const MULTIPART_SIZE = dataoceanConfig.MULTIPART_SIZE;

export const listFiles = async (
    path,
    limit = dataoceanConfig.paginationLimit,
    ascend = dataoceanConfig.sortOrder,
    url_duration = dataoceanConfig.url_duration,
    next_page_token) => {

    try {
        // console.log('listFiles');
        const options = {
            url: DataOceanV3.Files.FilesList,
            method: 'GET',
            params: {
                path,
                limit,
                ascend,
                next_page_token,
                url_duration
            }
        }

        const response = await requestHandler(options);
        return { data: response.files }
    }
    catch (error) {
        return { error }
    }
};
export const getUploadSignedURL = async (directoryPath, file) => {
    try {
        // console.log('getSignedURL');
        const path = `${directoryPath}/${file.name}`;
        const multipart = file.size > MULTIPART_SIZE;

        const signedUrlOptions = {
            url: DataOceanV3.Files.Files,
            method: 'POST',

            data: {
                file: {
                    path,
                    regions: ["us1"],
                    multipart
                }
            }
        }

        const data = await requestHandler(signedUrlOptions);

        return { data }
    }
    catch (error) {
        return { error }
    }
};
export const uploadFile = async (signedUrlResponse, file, progress, cancelTokenSource) => {
    try {
        // console.log('uploadFiles');
        const st = Date.now();
        const multipart = file.size > MULTIPART_SIZE;

        const uploadUrl = signedUrlResponse.file.upload.url;

        let uploadResponse
        if (multipart)
            uploadResponse = await multiPartUpload(file, signedUrlResponse.file.id, signedUrlResponse.file.version, uploadUrl, progress, cancelTokenSource);
        else
            uploadResponse = await singlePartUpload(file, uploadUrl, progress, cancelTokenSource);


        console.log(`Upload completed ${Date.now() - st}`)
        return { data: uploadResponse }
    }
    catch (error) {
        if (axios.isCancel(error))
            return { error: "Aborted" }
        else
            return { error }
    }
};

const multiPartUpload = async (file, fileId, version, url, progress, cancelTokenSource) => {
    const name = file.name;
    const fileSize = file.size;
    const noOfChunks = Math.floor(fileSize / CHUNK_SIZE);

    console.log(`File size : ${fileSize}`);
    console.log(`Chunk size : ${CHUNK_SIZE}`);
    console.log(`No of Chunks : ${noOfChunks}`);

    var parts = [];

    for (let partIndex = 0; partIndex < noOfChunks; partIndex++) {
        let start = partIndex * CHUNK_SIZE;
        let end = start + CHUNK_SIZE;

        //final chunk
        if (partIndex + 1 === noOfChunks) {
            end = fileSize;
        }
        const part_number = partIndex + 1;

        const uploadResponse = await uploadParts(file, url.replace("*", part_number), start, end, (partProgress) => {

            let total = Math.round((partIndex + partProgress) * 100 / noOfChunks);
            progress({ name, value: total })
        }, cancelTokenSource);
        parts.push({
            etag: uploadResponse.headers['etag'],
            part_number
        });

    }

    const response = await multipartAssemble(fileId, version, parts);
    return response;
};

const uploadParts = async (uploadedFile, url, start, end, progress, cancelTokenSource) => {

    const data = await getBlob(uploadedFile.slice(start, end));
    const uploadOptions = {
        url,
        method: 'PUT',
        data,
        cancelToken: cancelTokenSource.token,
        onUploadProgress: (progressEvent) => {

            let value = (progressEvent.loaded) / progressEvent.total;
            progress(value);
        }
    };
    const uploadResponse = await axios.request(uploadOptions);
    return uploadResponse;
};

const getBlob = async (chunkFileObject) => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = () => {
            reject(reader.error);
        }
        reader.readAsArrayBuffer(chunkFileObject);
    });
};
const multipartAssemble = async (fileId, version, parts) => {
    const options = {
        url: DataOceanV3.Files.MultiPartAssemble.replace(":fileId", fileId),
        method: 'POST',
        params: {
            version
        },
        data: {
            upload: {
                parts
            }
        }
    }
    const response = await requestHandler(options);
    return response;
}
const singlePartUpload = async (file, url, progress, cancelTokenSource) => {
    const name = file.name;
    const uploadOptions = {
        url,
        method: 'PUT',
        headers: {
            "Content-Type": "application/octet-stream"
        },
        cancelToken: cancelTokenSource.token,
        data: file,
        onUploadProgress: (progressEvent) => {
            let value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            progress({ name, value })
        }
    }
    const uploadResponse = await axios.request(uploadOptions);
    return uploadResponse;
};

export const deleteFile = async (fileId) => {
    try {
        // console.log('actions.deleteFile');
        const options = {
            url: `${DataOceanV3.Files.Files}/${fileId}`,
            method: 'DELETE'
        }

        const data = await requestHandler(options);
        return { data }
    }
    catch (error) {
        return { error }
    }

};