
exports.log = (event, group, payload) => {
    if (process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "stg") {
        window.tdcafLogger.sendEvent(
            new window.tdcafLogger.BaseEvent(
                event,
                group,
                payload)
        );
    } else {
        return;
    }
}