import React, { useState, useContext } from "react";
import { Trash, FileEarmarkArrowDown } from "react-bootstrap-icons";
import ModalConfirmation from "../controls/ModalConfirmation";
import { deleteDirectory } from "../../actions/directories_action";
import { deleteFile } from "../../actions/files_action";
import { styles } from "../../style/explorer/Actions.styles";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { context } from "./context";
import insightsLogger from '../../insightsLogger';

const Actions = ({ id, name, isDirectory, downloadUrl }) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { refreshCallback } = useContext(context);


    const handleDeleteClose = async (isCancel) => {
        // console.log('Actions.handleDelete start')

        if (!isCancel) {
            setIsLoading(true);
            let result;

            if (isDirectory) {
                result = await deleteDirectory(id);
            } else {
                insightsLogger.log('EXPLORER', 'FILE_DELETE');
               
                result = await deleteFile(id);
               
            }

            await refreshCallback(result.error ? result.error : "Deleted successfully", result.error ? true : false);
            setIsLoading(false);
        }
        setShowDeleteDialog(false)
    }

    const renderTooltip = (value) => {
        return (<Tooltip>{value}</Tooltip>)
    }

    if (showDeleteDialog)
        return (<ModalConfirmation
            heading={isDirectory ? "Delete Directory" : "Delete File"}
            body={`Do you wish to permanently delete "${name}"?`}
            okButtonText='Yes'
            handleClose={handleDeleteClose}
            isLoading={isLoading}
        />);
    else
        return (<div>
            {id &&
                <OverlayTrigger overlay={renderTooltip("Delete")}>
                    <Button variant="outline" style={styles.button}>
                        <Trash style={styles.icon} onClick={() => { setShowDeleteDialog(true) }} />
                    </Button>
                </OverlayTrigger>
            }
            {downloadUrl &&
                <OverlayTrigger overlay={renderTooltip("Download")}>
                    <Button variant="outline" style={styles.button} href={downloadUrl} download={name}>
                        <FileEarmarkArrowDown style={styles.icon} />
                    </Button>
                </OverlayTrigger>
            }

        </div >
        );


}
export default Actions;