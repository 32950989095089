export const styles = {
    root: {
        border: "solid 1px #d7d7db",
        height: "100%",
    },
    body: {
        height: "100%",
        overflowX: "auto",
        overflowY: "auto"
    },
    propertyValue: {
        overflowWrap: "break-word"
    }
}