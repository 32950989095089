import React, { useEffect, useContext } from "react";
import DirectoriesRow from "./directories/DirectoriesRow";
import FilesRow from "./files/FilesRow";
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { useState, } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ExplorerInfoPanel from "./ExplorerInfoPanel";
import { withRouter } from 'react-router';
import { styles } from "../../style/explorer/ExplorerTable.styles";
import { context } from "./context";
import { WebApp } from "../../config/apiPaths";
const ExplorerTable = ({ directories, files, history }) => {
    // console.log('ExplorerTable start');
    const { message } = useContext(context);
    const [height, setHeight] = useState(window.innerHeight);
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const [properties, setProperties] = useState(null);

    const directoriesRender = DirectoriesRow(directories);
    const filesRender = FilesRow(files);
    const updatewindowHeight = () => {

        setHeight(window.innerHeight)
    };
    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            if (row.isDirectory) {
                history.push(`?path=${row.path}`)
            }
        }, onClick: (e, row, rowIndex) => {
            setProperties(row);
            if (!showInfoPanel)
                setTimeout(() => setShowInfoPanel(true), 200);

        }

    };
    useEffect(() => {
        window.addEventListener('resize', updatewindowHeight);

        return (() => {
            window.removeEventListener('resize', updatewindowHeight);
        })
    })


    return (
        <Container fluid  >
            <Row>
                <Col xs={showInfoPanel ? 9 : 12} style={{ height: message ? (height - 257) : (height - 180), overflowX: "auto", overflowY: "auto" }} >
                    <BootstrapTable rowEvents={rowEvents}

                        bootstrap4
                        hover
                        rowStyle={styles.tableRow}
                        striped={false}
                        bordered={false}
                        keyField='id'
                        data={directoriesRender.concat(filesRender)}
                        columns={columns} />
                </Col>
                {showInfoPanel &&
                    <Col xs={3} style={styles.root} >
                        <ExplorerInfoPanel properties={properties} handleClose={() => setShowInfoPanel(false)} />
                    </Col>}
            </Row>
        </Container>
    )

};

const columns = [
    {
        dataField: 'type',
        text: 'Type',
        headerStyle: { width: 50, textAlign: 'center' },
    },
    {
        dataField: 'isDirectory',
        text: 'isDirectory',
        hidden: true
    }, {

        dataField: 'path',
        text: 'path',
        hidden: true
    }, {

        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => {
            if (!row.isDirectory && row.downloadUrl) {
                const path = `${WebApp.Previewer}?filepath=${row.downloadUrl}&filename=${row.name}`;
                return <a target="_blank" href={path} >{cell}</a>;
            }
            return cell;
        }
    }, {
        dataField: 'created',
        text: 'Created',
        sort: true,
        formatter: (cell, row) => {
            return cell ? cell.split(":", 2).join(":").replace("T", " ") : "-";
        }
    }, {
        dataField: 'updated',
        text: 'Updated',
        formatter: (cell, row) => {
            return cell ? cell.split(":", 2).join(":").replace("T", " ") : "-";
        },
        sort: true
    }, {
        dataField: 'size',
        text: 'Size',
        sort: true
    }, {
        dataField: 'actions',
        text: 'Actions'
    }];

export default withRouter(ExplorerTable);